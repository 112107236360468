import React, { useMemo, useState } from "react";
import _ from "lodash";
import { graphql, Link } from "gatsby";
import tw, { styled } from "twin.macro";

import Layout from "../components/Layout";
import Header from "@shared/components/Header";
import PaginatedTable from "@shared/components/PaginatedTable";
import { MAIN_COLOR, SITE } from "../config";

const Container = styled.div`
  ${tw`p-5`}
`;
const TableTitle = styled.h6`
  ${tw`text-gray-600 font-bold my-4`}
`;

const ButtonContainer = styled.div`
  ${tw`flex sm:my-4 [align-self: flex-end]`}
`;

const ActionButton = styled.button`
  ${tw`mr-1 sm:mr-2 flex items-center border border-gray-200 sm:px-2 sm:py-1 rounded not-disabled:hover:bg-gray-200 `}
  &:disabled > img {
    opacity: 0.2;
  }
  &:disabled > span {
    opacity: 0.2;
  }
  & > span {
    ${tw`ml-0.5 font-bold text-sm sm:text-base hidden sm:inline`}
  }
`;

const hittingHeaders = [
  {
    Header: "Player",
    accessor: "player",
    sortType: "basic",
  },
  {
    Header: "Team",
    accessor: "team",
    sortType: "basic",
  },
  {
    Header: "PA",
    accessor: "pa",
    sortType: "basic",
  },
  {
    Header: "AB",
    accessor: "ab",
    sortType: "basic",
  },
  {
    Header: "H",
    accessor: "h",
    sortType: "basic",
  },
  {
    Header: "AVG",
    accessor: "avg",
    sortType: "basic",
  },
  {
    Header: "OBP",
    accessor: "obp",
    sortType: "basic",
  },
  {
    Header: "SLG",
    accessor: "slg",
    sortType: "basic",
  },
  {
    Header: "OPS",
    accessor: "ops",
    sortType: "basic",
  },
  {
    Header: "1B",
    accessor: "h1b",
    sortType: "basic",
  },
  {
    Header: "2B",
    accessor: "h2b",
    sortType: "basic",
  },
  {
    Header: "3b",
    accessor: "h3b",
    sortType: "basic",
  },
  {
    Header: "HR",
    accessor: "hr",
    sortType: "basic",
  },
  {
    Header: "RBI",
    accessor: "rbi",
    sortType: "basic",
  },
  {
    Header: "R",
    accessor: "r",
    sortType: "basic",
  },
  {
    Header: "BB",
    accessor: "bb",
    sortType: "basic",
  },
  {
    Header: "HBP",
    accessor: "hbp",
    sortType: "basic",
  },
  {
    Header: "K-L",
    accessor: "k_l",
    sortType: "basic",
  },
  {
    Header: "K",
    accessor: "k",
    sortType: "basic",
  },
  {
    Header: "SAC",
    accessor: "sac",
    sortType: "basic",
  },
  {
    Header: "SF",
    accessor: "sf",
    sortType: "basic",
  },
  {
    Header: "RE",
    accessor: "re",
    sortType: "basic",
  },
  {
    Header: "FC",
    accessor: "fc",
    sortType: "basic",
  },
  {
    Header: "SB",
    accessor: "sb",
    sortType: "basic",
  },
  {
    Header: "CS",
    accessor: "cs",
    sortType: "basic",
  },
  {
    Header: "PIK",
    accessor: "pik",
    sortType: "basic",
  },
  {
    Header: "TB",
    accessor: "tb",
    sortType: "basic",
  },
];

const pitchingHeaders = [
  {
    Header: "Player",
    accessor: "player",
    sortType: "basic",
  },
  {
    Header: "Team",
    accessor: "team",
    sortType: "basic",
  },
  {
    Header: "IP",
    accessor: "ip",
    sortType: "basic",
  },
  {
    Header: "GS",
    accessor: "gs",
    sortType: "basic",
  },
  {
    Header: "W",
    accessor: "w",
    sortType: "basic",
  },
  {
    Header: "L",
    accessor: "l",
    sortType: "basic",
  },
  {
    Header: "ERA",
    accessor: "era",
    sortType: "basic",
  },
  {
    Header: "WHIP",
    accessor: "whip",
    sortType: "basic",
  },
  {
    Header: "SV",
    accessor: "sv",
    sortType: "basic",
  },
  {
    Header: "BS",
    accessor: "bs",
    sortType: "basic",
  },
  {
    Header: "H",
    accessor: "H",
    sortType: "basic",
  },
  {
    Header: "R",
    accessor: "R",
    sortType: "basic",
  },
  {
    Header: "ER",
    accessor: "er",
    sortType: "basic",
  },
  {
    Header: "BB",
    accessor: "bb",
    sortType: "basic",
  },
  {
    Header: "K",
    accessor: "k",
    sortType: "basic",
  },
  {
    Header: "HBP",
    accessor: "hbp",
    sortType: "basic",
  },
  {
    Header: "CG",
    accessor: "cg",
    sortType: "basic",
  },
  {
    Header: "SO",
    accessor: "so",
    sortType: "basic",
  },
  {
    Header: "K/7",
    accessor: "k7",
    sortType: "basic",
  },
  {
    Header: "BB/7",
    accessor: "bb7",
    sortType: "basic",
  },
];

const StatsPage = ({ data, location }) => {
  const { seo, hero, tabs, title, hittingStats, pitchingStats } =
    data.markdownRemark.frontmatter;
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);

  const formattedHittingStats = useMemo(
    () => getFormattedHittingStats(hittingStats, year),
    [year, hittingStats]
  );

  const formattedPitchingStats = useMemo(
    () => getFormattedPitchingStats(pitchingStats, year),
    [year, pitchingStats]
  );

  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        <ButtonContainer>
          {[
            { label: currentYear, value: currentYear },
            { label: "All-Time", value: null },
          ].map(({ label, value }, idx) => (
            <ActionButton
              onClick={() => setYear(value)}
              disabled={value === year}
              key={idx}
            >
              {/* <ActionSvg src="/img/svg/list.svg" alt="list_view" /> */}
              <span>{label}</span>
            </ActionButton>
          ))}
        </ButtonContainer>
        {title && (
          <Header
            title={title}
            heroData={hero}
            tabsData={tabs}
            color={MAIN_COLOR}
            site={SITE}
          />
        )}
        <TableTitle>HITTING</TableTitle>

        <PaginatedTable
          items={formattedHittingStats}
          headers={hittingHeaders}
          color={MAIN_COLOR}
        />

        <TableTitle>PITCHING</TableTitle>

        <PaginatedTable
          items={formattedPitchingStats}
          headers={pitchingHeaders}
          color={MAIN_COLOR}
        />
      </Container>
    </Layout>
  );
};

export default StatsPage;

export const pageQuery = graphql`
  query StatsPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "stats-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        tabs {
          label
          url
          icon
        }
        title
        hittingStats {
          player
          season
          team
          pa
          ab
          h
          # avg // avg = h/ab
          # obp // obp = (h + bb + hbp) / (ab + bb + hbp + sf)
          # slg // slg = tb/ab
          # ops // ops = obp + slg
          h1b
          h2b
          h3b
          hr
          rbi
          r
          bb
          hbp
          k_l
          k
          sac
          sf
          re
          fc
          sb
          cs
          pik
          tb
        }
        pitchingStats {
          player
          season
          team
          ip
          gs
          w
          l
          # era // era = er/ip * 9
          # whip // (bb + h)/ip
          sv
          bs
          h
          r
          er
          bb
          k
          hbp
          cg
          so
          # k7 // k7 = k/ip * 7
          # bb7 // bb7 = bb/ip * 7
        }
      }
    }
  }
`;

const getFormattedHittingStats = (items = [], year) => {
  let formattedItems = [];

  let displayItems = items.filter((item) =>
    year ? item.season === year : true
  );
  let itemsGroupedByPlayerAndTeam = _.groupBy(
    displayItems,
    (item) => `${item.player} ${item.team}`
  );

  Object.values(itemsGroupedByPlayerAndTeam).forEach((group) => {
    const sumStats = sumHittingStats(group);
    formattedItems.push(sumStats);
  });

  return formattedItems;
};

const sumHittingStats = (group = []) => {
  // sum stats
  let sumStats = {};
  if (group.length === 1) {
    sumStats = group[0];
  } else {
    sumStats = group.reduce((acc, obj) => {
      _.forOwn(obj, (value, key) => {
        if (_.isNumber(value)) {
          // sum all number field together
          if (!acc[key]) {
            acc[key] = 0;
          }
          if (key === "season") {
            acc[key] = value;
          } else {
            acc[key] += value;
          }
        } else {
          // keep none number field the same
          acc[key] = value;
        }
      });
      return acc;
    }, {});
  }

  // calculate the remaining stats
  const { h, ab, bb, hbp, sf, tb } = sumStats;

  let avg = h / ab;
  let obp = (h + bb + hbp) / (ab + bb + hbp + sf);
  let slg = tb / ab;
  let ops = obp + slg;
  _.set(sumStats, "avg", avg);
  _.set(sumStats, "obp", obp);
  _.set(sumStats, "slg", slg);
  _.set(sumStats, "ops", ops);

  return sumStats;
};

const getFormattedPitchingStats = (items = [], year) => {
  let formattedItems = [];

  let displayItems = items.filter((item) =>
    year ? item.season === year : true
  );

  let itemsGroupedByPlayerAndTeam = _.groupBy(
    displayItems,
    (item) => `${item.player} ${item.team}`
  );

  Object.values(itemsGroupedByPlayerAndTeam).forEach((group) => {
    const sumStats = sumPitchingStats(group);
    formattedItems.push(sumStats);
  });

  return formattedItems;
};

const sumPitchingStats = (group = []) => {
  // sum stats
  let sumStats = {};
  if (group.length === 1) {
    sumStats = group[0];
  } else {
    sumStats = group.reduce((acc, obj) => {
      _.forOwn(obj, (value, key) => {
        if (_.isNumber(value)) {
          // sum all number field together
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += value;
        } else {
          // keep none number field the same
          acc[key] = value;
        }
      });
      return acc;
    }, {});
  }

  // calculate the remaining stats
  const { er, ip, bb, h, k } = sumStats;

  let era = (er / ip) * 9;
  let whip = (h + bb) / ip;
  let k7 = (k / ip) * 7;
  let bb7 = (bb / ip) * 7;
  _.set(sumStats, "era", era);
  _.set(sumStats, "whip", whip);
  _.set(sumStats, "k7", k7);
  _.set(sumStats, "bb7", bb7);

  return sumStats;
};
